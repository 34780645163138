<template lang="pug">
div
	.module(:class="{'vertical-center':vCenter}" ref='module')
		h4 Welcome Back
		form
			.form-group
				input(type='text', v-model='uname' placeholder='Username or Email' required)
				.invalid-feedback(v-if="unameIsInvalid") Please enter your username or email
			.form-group
				input(type='password', v-model='passw' placeholder='Password' autocomplete='off' required)
				.invalid-feedback(v-if="passwIsInvalid") Password must be at least 8 characters
			button(@click='onSignInClicked').btn.blue Sign In

		.hr-text
			span Or Sign In With
		div
			.btn.icon.gg(@click='onSocialLogin')
				.logo
				.text Google
			.btn.icon.fb(@click='onSocialLogin')
				.logo
				.text Facebook
		//- .forgot-password(@click='onLinkClick') I forgot my username or password
		//- .no-account
		//- 	| Don't have an account? &nbsp;
		//- 	router-link(to="/create-account") Click here to create one

</template>

<script>

export default{
	data(){
		return {
			vCenter: false,
			unameIsInvalid : false,
			passwIsInvalid : false
		}
	},
	mounted() {
		this.onWindowResize();
		window.addEventListener('resize', this.onWindowResize);
	},
	beforeUnmount(){
		window.removeEventListener('resize', this.onWindowResize);
	},
	methods:{
		onWindowResize(){
			let wh = window.innerHeight;
			let mh = this.$refs.module.offsetHeight;
			this.vCenter = wh > mh;
		},
		onSignInClicked(e){
			this.unameIsInvalid = (!this.uname || this.uname.length < 4)
			this.passwIsInvalid = (!this.passw || this.passw.length < 4)
			if (!this.unameIsInvalid && !this.passwIsInvalid){
				(async () => {
					const rawResponse = await fetch('/login', {
						method: 'POST',
						credentials: 'include',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({uname: this.uname, passw: this.passw})
					})
					.then(response => response.json())
					.then(response => {
						if (response.account != null){
							this.$store.commit('setUserData', response.account);
							this.$router.push('/admin/dashboard');
						}	else{
							alert("Invalid credentials");
						}
					});
				})();
			}
			e.preventDefault();
		},
		onSocialLogin()
		{
			alert('Coming Soon');
		},
		onLinkClick()
		{
			alert('Coming Soon');
		}
	}

}

</script>

<style lang="stylus" scoped>

$component_border_color = #ced4da

.forgot-password, .no-account
	font-size .9rem
	text-align center

.forgot-password
	cursor pointer
	margin-top 20px

.forgot-password:hover
	color #427fe1

.no-account
	margin-top 10px

.vertical-center
	position absolute
	top 42%; left 50%
	transform translate(-50%, -50%)

</style>